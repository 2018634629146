<template>
  <div class="invoicMain">
    <!-- 发票头开始 -->
    <div class="invoiceHeader">
      <div class="invoiceHeader-left">
        <ul class="headerLeft">
          <li>
            <label>机器编号：</label><span>{{ orderData.applyInfo.tax_disk_no }}</span>
          </li>
        </ul>
        <div class="headerMiddle">
          <h1>{{ orderData.applyInfo.invoice_type_str }}</h1>
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </div>

      <ul class="headerRight">
        <li><label>发票代码：</label><span>{{ orderData.invoiceInfo.invoice_code }}</span></li>
        <li><label>发票号码：</label><span>{{ orderData.invoiceInfo.invoice_no }}</span></li>
        <li><label>开票日期：</label><span>{{ orderData.invoiceInfo.invoice_date }}</span></li>
        <li><label>校验码：</label><span>{{ orderData.invoiceInfo.invoice_check_code }}</span></li>
      </ul>
    </div>
    <!-- 发票头结束 -->

    <!-- 发票体开始 -->
    <div class="invoiceBody">
      <div class="userInfo">
        <div class="buy">购买方</div>
        <ul>
          <li><label>名称：</label><span>{{ orderData.applyInfo.buyer_name }}</span></li>
          <li><label>纳税人识别号：</label><span>{{ orderData.applyInfo.buyer_tax_no }}</span></li>
          <li><label>地址、电话：</label><span>{{ orderData.applyInfo.buyer_address_phone }}</span></li>
          <li><label>开户行及账号：</label><span>{{ orderData.applyInfo.buyer_bank_account }}</span></li>
        </ul>
        <div class="password">密码区</div>
        <div class="pwdInfo">{{ orderData.invoiceInfo.tax_control_code }}</div>
      </div>
      <div>
        <table class="GoodsTable" cellpadding="0" cellspacing="0">
          <tr>
            <td style="width: 30%">货物或应税劳务、服务名称</td>
            <td style="width: 10%">规格型号</td>
            <td style="width: 5%">单位</td>
            <td style="width: 10%">数量</td>
            <td style="width: 10%">单价(不含税)</td>
            <td style="width: 16%">金额(不含税)</td>
            <td style="width: 5%">税率</td>
            <td style="width: 16%; border-right: none;">税额</td>
          </tr>
          <tr class="invoice-list" v-for="op in orderData.applyInfo.invoice_subject_data" :key="op.id">
            <td>{{ op.goods_name }}</td>
            <td>{{ op.goods_specification }}</td>
            <td>{{ op.goods_unit }}</td>
            <td>{{ op.goods_quantity }}</td>
            <td>
              <!-- {{ op.goods_price }} -->
            </td>
            <td>
              <!-- {{ op.goods_total_price }} -->
            </td>
            <td>
              <!-- {{ op.goods_tax_rate }} -->
            </td>
            <td style="border-right: none;">
              <!-- {{ op.goods_total_tax }} -->
            </td>
          </tr>
          <tr class="total">
            <td>合计</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td style="text-align: center;">￥{{ this.orderData.invoiceInfo.invoice_total_price }}</td>
            <td></td>
            <td style="text-align: center; border-right: none;">￥{{ this.orderData.invoiceInfo.invoice_total_tax }}</td>
          </tr>
          <tr class="GoodsTotal">
            <td style="text-align: center;">价税合计(大写)</td>
            <td colspan="7" style="border-right: none;">
              <div style="width: 100%;display:flex">
                <div type="text" style="width: 70%; margin-left: 10px;">
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 10])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 10])) }} 佰 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 9])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 9])) }} 拾 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 8])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 8])) }} 万 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 7])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 7])) }} 仟 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 6])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 6])) }} 佰 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 5])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 5])) }} 拾 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 4])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 4])) }} 元 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 2])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 2])) }} 角 </span>
                  <span class="weightFont" :class="getCurrency(cnMoney[cnMoney.length - 1])">{{ numberToChinese(getFeeDigit(cnMoney[cnMoney.length - 1])) }} 分 </span>
                </div>
                <div type="text" style="width: 30%; margin-left: 20px;"> (小写)￥{{ cnMoney }}</div>
              </div>
            </td>
          </tr>
        </table>
        <div class="sellInfo">
          <div class="buy">销售方</div>
          <ul>
            <li><label>名称：</label><span>{{ orderData.applyInfo.tax_tray_info.sale_company_name }}</span></li>
            <li><label>纳税人识别号：</label><span>{{ orderData.applyInfo.tax_tray_info.sale_company_tax_no }}</span></li>
            <li><label>地址、电话：</label><span>
              {{ orderData.applyInfo.tax_tray_info.sale_company_address }}
              {{ orderData.applyInfo.tax_tray_info.sale_company_mobile }}
            </span></li>
            <li><label>开户行及账号：</label><span>
              {{ orderData.applyInfo.tax_tray_info.sale_company_bank }}
              {{ orderData.applyInfo.tax_tray_info.sale_company_account }}
            </span></li>
          </ul>
          <div class="password">备注</div>
          <div class="pwdInfo"></div>
        </div>
      </div>
    </div>
    <!-- 发票体结束 -->

    <!-- 发票底部开始 -->
    <ul class="invoicetFooter">
      <li><span style="color: red;">* </span><label>收款人：{{ orderData.applyInfo.payee }}</label></li>
      <li><span style="color: red;">* </span><label>复核：{{ orderData.applyInfo.checker }}</label></li>
      <li><span style="color: red;">* </span><label>开票人：{{ orderData.applyInfo.drawer }}</label></li>
      <li><span style="color: red;">* </span><label>销售单位：（章）</label></li>
    </ul>
    <!-- 发票底部结束 -->
  </div>
</template>

<script>
import { numberToChinese } from '@/utils/util'

export default {
  name: 'InvoiceDetail',
  props: {
    currentData: {
      type: Object,
      default: null
    }
  },
  watch: {
    currentData: {
      handler(val, oldVal) {
        this.initData(val)
      },
      deep: true
    }
  },
  created() {
    this.initData(this.currentData)
  },
  data() {
    return {
      orderData: {},
      cnMoney: '' // 中文金额
    }
  },
  methods: {
    numberToChinese,
    initData(data) {
      this.orderData = data
      this.cnMoney = this.orderData.invoiceInfo.invoice_total_price_tax.toFixed(2) + ''
    },
    getCurrency(s) {
      if (s === undefined || '') {
        return 'hidden'
      } else {
        return 'show'
      }
    },
    getFeeDigit(s) {
      if (s) {
        return s
      } else {
        return ' '
      }
    }
  }
}
</script>

<style scoped>
ul,
ul li {
  list-style: none;
  margin: 0;
  padding: 0;
}

label {
  color: black;
}

.invoicMain {
  width: 900px;
  margin: 0 auto;
  font-size: 15px;
  padding: 20px;
  border: 1px solid #a5a3a1e7;
}

.invoiceHeader {
  height: 126px;
  display: flex;
  align-items: center;
}

.headerLeft li:nth-child(1) {
  text-indent: 1em;
}

.headerLeft li img {
  width: 105px;
  height: 105px;
}

.headerMiddle h1 {
  font-size: 18px;
  color: grey;
  text-align: center;
}

.line {
  width: 350px;
  margin: 1px 0px;
  border-top: #aaa8a6e7 1px solid;
  border-bottom: #aaa8a6e7 1px solid;
}

.headerRight li {
  line-height: 24px;
}

.invoiceBody {
  border: 1px solid #a5a3a1e7;
}

.userInfo {
  width: 100%;
  display: flex;
  align-items: center;
  height: 150px;
  border-bottom: 1px solid #a5a3a1e7;
}

.userInfo ul {
  width: 50%;
  margin: 0 5px;
  padding: 0;
}

.userInfo ul li {
  line-height: 24px;
}

.sellInfo {
  width: 100%;
  display: flex;
  align-items: center;
  height: 150px;
}

.sellInfo ul {
  width: 50%;
  margin: 0 5px;
  padding: 0;
}

.sellInfo ul li {
  line-height: 24px;
}

.buy {
  width: 50px;
  border-right: 1px solid #aaa8a6e7;
  padding: 0 10px;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  color: black;
}

.password {
  width: 50px;
  padding: 0 10px;
  border-right: 1px solid #aaa8a6e7;
  border-left: 1px solid #aaa8a6e7;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  color: black;
}

.pwdInfo {
  flex: 1;
  padding: 0 10px;
  text-align: center;
}

.goodsInfo {
  height: 210px;
  margin: 0;
  padding: 0;
}

.goodsInfo li {
  display: flex;
  text-align: center;
}

.name {
  width: 260px;
  border-right: 1px solid #aaa8a6e7;
}

.spec {
  width: 140px;
  border-right: 1px solid #aaa8a6e7;
}

.qty {
  width: 108px;
  border-right: 1px solid #aaa8a6e7;
}

.unit,
.taxRate {
  width: 65px;
  border-right: 1px solid #aaa8a6e7;
}

.qty,
.price {
  width: 160px;
  border-right: 1px solid #aaa8a6e7;
}

.money {
  flex: 1;
  border-right: 1px solid #aaa8a6e7;
}

.taxAmount {
  flex: 1;
}

.GoodsTable {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.GoodsTable .invoice-list {
  height: 24px;
  text-align: center;
}

.GoodsTable .invoice-list td {
  color: gray;
}

.GoodsTable td {
  border-right: 1px solid #7c7b79e7;
  color: black;
}

.GoodsTable tr:nth-child(1),
.GoodsTable tr:nth-last-child(2) {
  height: 24px;
}

.GoodsTable tr:nth-last-child(1) {
  height: 34px;
}

.GoodsTable tr:nth-child(1) td {
  text-align: center;
}

.GoodsTotal {
  border-top: 1px solid #aaa8a6e7;
  border-bottom: 1px solid #aaa8a6e7;
}

.total td:nth-child(1) {
  text-align: center;
}

.invoicetFooter {
  margin: 0;
  padding: 0;
  display: flex;
}

.invoicetFooter li {
  width: 20%;
}
.invoiceHeader-left{
  width: 67%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerRight {
  margin-left: 28px;
}
.headerMiddle {
  margin-right: 15px;
}
.weightFont{
  font-weight: bold;font-size: 16px
}
.show{
  display: inline;
}
.hidden{
  display: none;
}
</style>
