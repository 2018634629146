<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="税盘">
                <a-select
                  v-model="queryParam.tax_tray_id"
                  allowClear
                  placeholder="请选择税盘"
                  @change="loadData"
                >
                  <a-select-option v-for="op in taxTrayOps" :key="op.id">{{ op.tax_tray_no + '——' + op.tax_tray_name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="发票种类">
                <a-select
                  v-model="queryParam.invoice_type_code"
                  allowClear
                  placeholder="发票种类"
                  @change="loadData"
                >
                  <a-select-option v-for="op in invoiceTypeCodeOps" :key="op.code">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="发票服务">
                <a-select
                  v-model="queryParam.service_code"
                  allowClear
                  placeholder="发票种类"
                  @change="loadData"
                >
                  <a-select-option v-for="op in invoiceServiceCodeOps" :key="op.code">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="购方客户名称">
                <a-input v-model.trim="queryParam.buyer_name" placeholder="根据购方客户名称查询" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="票号">
                <a-input v-model.trim="queryParam.invoice_no" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="开票时间">
                <a-range-picker @change="openTimeChange"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="申请时间">
                <a-range-picker @change="applyDateChange"/>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="query" icon="search" @click="queryList">查询</a-button>
      </div>

      <a-table
        size="small"
        row-key="id"
        bordered
        :scroll="{ x: 'max-content' }"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="handleChangeTable"
        :row-selection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
      >
        <template slot="action" slot-scope="text, record">
          <a v-if="record.apply_result !== '0'" @click="handleAgainApply(record)">重开</a>
        </template>
        <template slot="invoiceSubjectDataSlot" slot-scope="text">
          <a-popover placement="bottom">
            <template v-if="text && text.length > 0">
              <span>{{ text[0].goods_name }}</span>
              <span style="color: #FAAD14">{{ text.length !== 0 ? ` (${text.length})个` : null }}</span>
            </template>
            <template slot="content">
              <a-table
                size="small"
                row-key="id"
                bordered
                :scroll="{ x: 'max-content' }"
                :columns="invoiceSubjectColumns"
                :data-source="text"
                :pagination="false"
              ></a-table>
            </template>
          </a-popover>
        </template>
        <template slot="invoiceApplyResultDataSlot" slot-scope="text, record">
          <a-popover placement="bottom" v-if="record.service_code !== 'invoiceInvalid'">
            <template v-if="text && text.length > 0">
              <span>{{ text[0].invoice_no }}</span>
              <span style="color: #FAAD14">{{ text.length !== 0 ? ` (${text.length})个` : null }}</span>
            </template>
            <template slot="content">
              <a-table
                size="small"
                row-key="id"
                bordered
                :scroll="{ x: 'max-content' }"
                :columns="invoiceApplyResultColumns"
                :data-source="text"
                :pagination="false"
              >
                <template slot="invoiceNoSlot" slot-scope="childText, childRecord">
                  <a @click="getInvoiceDetail(childRecord, record)">{{ childText }}</a>
                </template>
                <template slot="eInvoiceUrlSlot" slot-scope="childText">
                  <a @click="openEinvoiceUrl(childText)" target="_blank" v-if="childText">下载</a>
                </template>
              </a-table>
            </template>
          </a-popover>
          <span v-else>{{record.original_invoice_no}}</span>
        </template>
        <template slot="taxTrayInfoSlot" slot-scope="text, record">
          <a-popover placement="right">
            {{ record.tax_tray_name }}
            <template slot="content">
              <span>税盘编号：{{ record.tax_disk_no }}</span> <br>
              <span>税盘名称：{{ record.tax_tray_name }}</span> <br>
              <span>销方地址及电话：{{ record.seller_address_phone }}</span> <br>
              <span>销方开户行及账号：{{ record.seller_bank_account }}</span> <br>
              <span>收款人：{{ record.payee }}</span> <br>
              <span>开票人：{{ record.drawer }}</span> <br>
              <span>审核人：{{ record.checker }}</span> <br>
            </template>
          </a-popover>
        </template>
        <template slot="buyerInfoSlot" slot-scope="text, record">
          <a-popover placement="right">
            {{ record.buyer_name }}
            <template slot="content">
              <span>购方单位代码：{{ record.buyer_tax_no }}</span> <br>
              <span>购方地址及电话：{{ record.buyer_address_phone }}</span> <br>
              <span>购方开户行及账号：{{ record.buyer_bank_account }}</span> <br>
              <span>购方手机号码：{{ record.buyer_phone }}</span> <br>
            </template>
          </a-popover>
        </template>
      </a-table>

      <!-- 查询发票详情 -->
      <a-drawer
        title="查看详情"
        placement="right"
        :width="1000"
        :visible="visible"
        :maskClosable="false"
        :destroyOnClose="true"
        @close="CloseInvoiceDetail"
      >
        <div>
          <a-button type="primary">发票打印</a-button>
          <a-button style="margin-left: 10px;">同步助手</a-button>
        </div>
        <div style="margin-top: 20px;">
          <InvoiceDetail :currentData="invoiceForm"></InvoiceDetail>
        </div>
        <div style="margin-top: 20px;">
          <a-button type="primary">作废</a-button>
        </div>
      </a-drawer>
    </a-card>
  </div>
</template>

<script>
import {
  listInvoiceTaxTray,
  initInvoiceApplyInfo,
  pageInvoiceApplyInfo,
  againApplyInvoiceForm4Fail
} from '@/api/fms'
import moment from 'moment'
import InvoiceDetail from '@/views/fms/invoice/InvoiceDetail.vue'

export default {
  name: 'FmsInvoiceApplyInfo',
  components: {
    InvoiceDetail
  },
  data() {
    return {
      queryParam: {},
      taxTrayOps: [], // 税盘列表
      invoiceTypeCodeOps: [], // 发票种类列表
      invoiceServiceCodeOps: [], // 调用服务code
      selectedRowKeys: [],
      selectedRows: [],
      columns: [
        {
          title: '税盘信息',
          scopedSlots: { customRender: 'taxTrayInfoSlot' }
        },
        {
          title: '发票流水号',
          dataIndex: 'serial_no'
        },
        {
          title: '开票日期',
          dataIndex: 'result_open_time',
          customRender: (text) => {
            return text ? moment(text).format('YYYY/MM/DD') : null
          }
        },
        {
          title: '发票服务',
          dataIndex: 'service_code',
          customRender: (text) => {
            for (const key in this.invoiceServiceCodeOps) {
              if (this.invoiceServiceCodeOps[key].code === text) {
                return this.invoiceServiceCodeOps[key].name
              }
            }
          }
        },
        {
          title: '发票种类名称',
          dataIndex: 'invoice_type_str'
        },
        {
          title: '购方客户信息',
          scopedSlots: { customRender: 'buyerInfoSlot' }
        },
        {
          title: '科目',
          dataIndex: 'invoice_subject_data',
          scopedSlots: { customRender: 'invoiceSubjectDataSlot' }
        },
        {
          title: '票号',
          dataIndex: 'invoice_apply_result_data',
          scopedSlots: { customRender: 'invoiceApplyResultDataSlot' }
        },
        {
          title: '发票类型',
          dataIndex: 'invoice_type',
          customRender: (text, record) => {
            if (record.service_code === 'invoiceInvalid') {
              return <a-tag color='red'>作废发票</a-tag>
            }
            return text === 0 ? <a-tag color='green'>正数发票</a-tag> : <a-tag color='red'>负数发票</a-tag>
          }
        },
        {
          title: '合计金额',
          dataIndex: 'invoice_total_price'
        },
        {
          title: '合计税额',
          dataIndex: 'invoice_total_tax'
        },
        {
          title: '税价合计',
          dataIndex: 'invoice_total_price_tax'
        },
        // {
        //   title: '全电纸质发票标志',
        //   dataIndex: 'paper_invoice_flag'
        // },
        {
          title: '特殊票种标识',
          dataIndex: 'invoice_special_mark'
        },
        {
          title: '含税标志',
          dataIndex: 'price_tax_mark',
          customRender: (text) => {
            return text === 0 ? '不含税' : '含税'
          }
        },
        {
          title: '清单标志',
          dataIndex: 'invoice_list_mark',
          customRender: (text) => {
            return text === 0 ? '无清单' : '带清单'
          }
        },
        {
          title: '征税方式',
          dataIndex: 'taxation_method',
          customRender: (text) => {
            if (text === 0) {
              return '普通征税'
            } else if (text === 2) {
              return '差额征税'
            } else {
              return '无'
            }
          }
        },
        {
          title: '备注',
          dataIndex: 'remarks'
        },
        {
          title: '红字信息表编号',
          dataIndex: 'red_info_no'
        },
        {
          title: '原发票代码',
          dataIndex: 'original_invoice_code'
        },
        {
          title: '原发票号码',
          dataIndex: 'original_invoice_no'
        },
        {
          title: '扣除额',
          dataIndex: 'deductible_amount'
        },
        {
          title: '处理结果代码',
          dataIndex: 'apply_result'
        },
        {
          title: '结果说明',
          dataIndex: 'result_message'
        },
        {
          title: '申请日期',
          dataIndex: 'created_at',
          customRender: (text) => {
            return text ? moment(text).format('YYYY/MM/DD') : null
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '50'],
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      invoiceApplyResultColumns: [
        {
          title: '发票号码',
          dataIndex: 'invoice_no',
          scopedSlots: { customRender: 'invoiceNoSlot' }
        },
        {
          title: '合计金额',
          dataIndex: 'invoice_total_price'
        },
        {
          title: '合计税额',
          dataIndex: 'invoice_total_tax'
        },
        {
          title: '价税合计',
          dataIndex: 'invoice_total_price_tax'
        },
        {
          title: '电子发票',
          dataIndex: 'einvoice_url',
          scopedSlots: { customRender: 'eInvoiceUrlSlot' }
        }
      ],
      invoiceSubjectColumns: [
        {
          title: '商品名称',
          dataIndex: 'goods_name'
        },
        {
          title: '规格型号',
          dataIndex: 'goods_specification'
        },
        {
          title: '计量单位',
          dataIndex: 'goods_unit'
        },
        {
          title: '商品数量',
          dataIndex: 'goods_quantity'
        },
        {
          title: '单价',
          dataIndex: 'goods_price'
        },
        {
          title: '金额',
          dataIndex: 'goods_total_price'
        },
        {
          title: '税额',
          dataIndex: 'goods_total_tax'
        },
        {
          title: '税率',
          dataIndex: 'goods_tax_rate'
        }
      ],
      visible: false,
      // 用于渲染查看发票详情的数据
      invoiceForm: {}
    }
  },
  mounted() {
    this.loadData()
    initInvoiceApplyInfo().then(res => {
      this.invoiceTypeCodeOps = res.INVOICE_TYPE_CODE_DATA
      this.invoiceServiceCodeOps = res.INVOICE_SERVICE_CODE_DATE
    })
    listInvoiceTaxTray().then(res => {
      this.taxTrayOps = res
    })
  },
  methods: {
    /**
     * 查询发票详情信息
     * @param invoiceInfo 发票信息
     * @param applyInfo 申请开票信息
     */
    getInvoiceDetail(invoiceInfo, applyInfo) {
      this.invoiceForm.invoiceInfo = Object.assign({}, invoiceInfo)
      this.invoiceForm.applyInfo = Object.assign({}, applyInfo)
      this.visible = true
    },
    openEinvoiceUrl(url) {
      window.open(url, true);
    },
    // 关闭查看发票详情
    CloseInvoiceDetail() {
      this.visible = false
    },
    loadData() {
      pageInvoiceApplyInfo(this.queryParam).then(res => {
        this.data = res.list
        this.pagination.total = res.total
      })
    },
    queryList() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.queryParam['page'] = 1
      this.queryParam['page_size'] = 10
      this.loadData()
    },
    handleChangeTable(page) {
      this.pagination.current = page.current
      this.pagination.pageSize = page.pageSize
      this.queryParam['page'] = page.current
      this.queryParam['page_size'] = page.pageSize
      this.loadData()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    openTimeChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['open_start_time'] = startDate
        this.queryParam['open_end_time'] = endDate
      } else {
        this.queryParam['open_start_time'] = null
        this.queryParam['open_end_time'] = null
      }
    },
    applyDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['apply_start_time'] = startDate
        this.queryParam['apply_end_time'] = endDate
      } else {
        this.queryParam['apply_start_time'] = null
        this.queryParam['apply_end_time'] = null
      }
    },
    handleAgainApply(record) {
      againApplyInvoiceForm4Fail({ 'invoice_apply_id': record.id }).then(_ => {
          this.$notification['success']({
            message: '提示',
            description: '重开成功'
          })
        })
    }
  }
}
</script>

<style scoped>

</style>
